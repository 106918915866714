export const filterProps = (obj, keys = [], include = true) =>
	Object.keys(obj)
		.filter((propKeyName) => {
			const found = keys.includes(propKeyName);
			return include ? found : !found;
		})
		.reduce(
			(acc, filteredPropKeyName) => ({
				...acc,
				[filteredPropKeyName]: obj[filteredPropKeyName],
			}),
			{},
		);

export const isPropDefined = (prop) => prop !== null && prop !== undefined;
