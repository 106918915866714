import { useMediaQuery } from "@react-hook/media-query";
import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
import { filterProps } from "@website-builder/utilities/utils/redesign.js";
import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { componentMapping, styleConfigPropsList } from "./constants";
import { getInlineStyle, StyledText } from "./style";

const Text = forwardRef(
	(
		{ variant, tag, style = {}, children, className, darkBg, ...restProps },
		ref,
	) => {
		const mappedElement = tag || componentMapping[variant] || "div";
		const isMobile = useMediaQuery(`(max-width: ${TABLET_M_BP}px)`);
		const mediaQuery = isMobile ? "mobile" : "desktop";
		const styleConfigProps = filterProps(restProps, styleConfigPropsList);
		const _restProps = filterProps(restProps, styleConfigPropsList, false);
		const inlineStyles = {
			...getInlineStyle(styleConfigProps),
			...style,
		};
		return (
			<StyledText
				as={mappedElement}
				variant={variant}
				mediaQuery={mediaQuery}
				ref={ref}
				style={inlineStyles}
				className={className}
				darkBg={darkBg}
				{..._restProps}
			>
				{children?.type === "doc" ? (
					<div
						className="rich-text-wrapper"
						dangerouslySetInnerHTML={{ __html: richTextResolver(children) }}
					/>
				) : (
					children
				)}
			</StyledText>
		);
	},
);

Text.defaultProps = {
	variant: "paragraph_M",
};

Text.propTypes = {
	variant: PropTypes.oneOf(Object.keys(componentMapping)),
	tag: PropTypes.string,
};

export default Text;
