import { isPropDefined } from "@website-builder/utilities/utils/redesign.js";
import styled from "styled-components";
import { styleConfigPropsList, variantCssPropertiesMapping } from "./constants";

export const transformToCssVariables = ({ mediaQuery, variant, property }) =>
	`var(--${mediaQuery}-${variantCssPropertiesMapping[variant]}-${property})`;

const nightshade_800_variants = [
	"headline_0",
	"headline_1",
	"headline_2",
	"headline_3",
	"headline_4",
	"headline_5",
	"headline_6",
	"paragraph_L_bold",
	"paragraph_M_bold",
	"paragraph_S_bold",
];
const nightshade_600_variants = [
	"paragraph_L",
	"paragraph_M",
	"paragraph_S",
	"label_L",
	"label_M",
];

const getVariantStyles = {
	fontFamily: ({ variant, mediaQuery }) =>
		transformToCssVariables({ mediaQuery, variant, property: "ff" }),
	fontWeight: ({ variant, mediaQuery }) =>
		transformToCssVariables({ mediaQuery, variant, property: "fw" }),
	lineHeight: ({ variant, mediaQuery }) =>
		transformToCssVariables({ mediaQuery, variant, property: "lh" }),
	fontSize: ({ variant, mediaQuery }) =>
		`calc(${transformToCssVariables({
			mediaQuery,
			variant,
			property: "fs",
		})} * 1px)`,
	letterSpacing: ({ variant, mediaQuery }) =>
		transformToCssVariables({ mediaQuery, variant, property: "ls" }),
	// paragraphSpacing: ({variant, mediaQuery}) => `calc(var(--${mediaQuery}-${variantCssPropertiesMapping[variant]}-ps) * 1px)`,
	textCase: ({ variant, mediaQuery }) =>
		transformToCssVariables({ mediaQuery, variant, property: "tc" }),
	textDecoration: ({ variant, mediaQuery }) =>
		transformToCssVariables({ mediaQuery, variant, property: "td" }),
	color: ({ variant, dark }) => {
		let defaultColor = "var(--primary-neutral-nightshade-600)";
		if (nightshade_800_variants.includes(variant)) {
			defaultColor = "var(--primary-neutral-nightshade-800)";
		}
		if (nightshade_600_variants.includes(variant)) {
			defaultColor = "var(--primary-neutral-nightshade-600)";
		}
		if (dark) {
			defaultColor = "var(--primary-neutral-white)";
		}
		return defaultColor;
	},
};

const getPropStyle = {
	color: ({ color }) => `var(--${color.base}-${color.color}-${color.shade})`,
	fontFamily: ({ fontFamily }) => `var(--ff-${fontFamily.typeface})`,
	fontWeight: ({ fontWeight }) =>
		`var(--fw-${fontWeight.typeface}-${fontWeight.weight})`,
	lineHeight: ({ lineHeight }) => `var(--lh-${lineHeight})`,
	fontSize: ({ fontSize }) => `var(--fs-${fontSize})`,
	letterSpacing: ({ letterSpacing }) => `var(--ls-${letterSpacing})`,
};

export const getInlineStyle = (props) => {
	const styles = {};
	styleConfigPropsList.forEach((styleConfigProp) => {
		if (
			props.hasOwnProperty(styleConfigProp) &&
			isPropDefined(props[styleConfigProp])
		) {
			styles[styleConfigProp] = getPropStyle[styleConfigProp](props);
		}
	});
	return styles;
};

export const StyledText = styled.div.attrs((props) => ({
	"data-testid": props.testID,
}))`
	font-family: ${getVariantStyles.fontFamily};
	font-weight: ${getVariantStyles.fontWeight};
	line-height: ${getVariantStyles.lineHeight};
	font-size: ${getVariantStyles.fontSize};
	letter-spacing: ${getVariantStyles.letterSpacing};
	text-transform: ${getVariantStyles.textCase};
	text-decoration: ${getVariantStyles.textDecoration};
	color: ${getVariantStyles.color};
	a {
		color: ${(props) => props?.darkBg && "#FFFFFF"};
	}
`;
