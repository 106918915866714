export const styleConfigPropsList = [
	"color",
	"fontFamily",
	"fontWeight",
	"lineHeight",
	"fontSize",
	"letterSpacing",
];

export const componentMapping = {
	headline_0: "h1",
	headline_1: "h1",
	headline_2: "h2",
	headline_3: "h3",
	headline_4: "h4",
	headline_5: "h5",
	headline_6: "h6",
	paragraph_L: "p",
	paragraph_L_bold: "p",
	paragraph_M: "p",
	paragraph_M_bold: "p",
	paragraph_S: "p",
	paragraph_S_bold: "p",
	label_L: "label",
	label_M: "label",
};

export const variantCssPropertiesMapping = {
	headline_0: "h0",
	headline_1: "h1",
	headline_2: "h2",
	headline_3: "h3",
	headline_4: "h4",
	headline_5: "h5",
	headline_6: "h6",
	paragraph_L: "body-l-regular",
	paragraph_L_bold: "body-l-bold",
	paragraph_M: "body-m-regular",
	paragraph_M_bold: "body-m-bold",
	paragraph_S: "body-s-regular",
	paragraph_S_bold: "body-s-bold",
	label_L: "label-l",
	label_M: "label-m",
};
